<template>
  <AddPushCardCom @saveSuccess="saveSuccess" />
</template>
<script>
  /**
   * @description 活动下创建文件
   */
  import AddPushCardCom from '../../../PlatformModule/PushCard/addCard.vue';
  export default {
    name: 'PushCardAdd',
    components: {
      AddPushCardCom
    },
    methods: {
      saveSuccess() {
        this.$router.replace({
          path: `${this.isEmbed ? '/embed' : ''}/live/pushCard/${this.$route.query.webinarId}`,
          query: {
            roomId: this.embedRoomId || this.$route.query.roomId,
            tab: 1
          }
        });
      }
    }
  };
</script>
